import React from 'react';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import { ComponentContent, CONTENT_SIZES } from 'Layout';

import Texts from '../../../../texts.json';

import styles from './SignInFailed.scss';

const LocalT = new MDText(Texts);

type SignInFailedProps = {
  onBackClick: () => void;
};

export const SignInFailed = ({ onBackClick }: SignInFailedProps) => (
  <div className={styles.component}>
    {/* @ts-expect-error empty className prop */}
    <ComponentContent maxWidth={CONTENT_SIZES.big}>
      <h2>{LocalT.translate('signInFailedView.title')}</h2>
    </ComponentContent>
    {/* @ts-expect-error empty className prop */}
    <ComponentContent maxWidth={CONTENT_SIZES.small}>
      <div className={styles.content}>
        <p>{LocalT.translate('signInFailedView.detail')}</p>
        <Button
          fullWidth
          reverse
          icon="chevron"
          label= {LocalT.translate('signInFailedView.buttonLabel')}
          onClick={onBackClick}
        />
      </div>
    </ComponentContent>
  </div>
);
