import React from 'react';

import { MDText } from 'i18n-react';

import { FireIcon } from '@motorway/mw-highway-code';

import { SocialValidationProps } from './SocialValidation';
import LocalTexts from './SocialValidationText.json';

const LocalT = new MDText(LocalTexts);

export const HEIGHT_TO_HIDE_FROM = 200;
export const HEIGHT_TO_HIDE_FROM_MOBILE = 100;
export const MINIMUM_WINDOW_WIDTH = 1320;
export const MINIMUM_SOLD_PER_MONTH = 10;

export const contentConfig = ({ brandName, numberOfCarsSold }: SocialValidationProps) => [
  {
    icon: <FireIcon />,
    text: LocalT.translate('title', {
      make: (<strong>{brandName}</strong>),
      num: numberOfCarsSold?.toLocaleString(),
    }),
  },
];
