import React, { useEffect } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { GAEvent, SIGN_IN_PAGE_EVENTS } from 'Utilities/analytics';

import { ComponentContent, CONTENT_SIZES } from 'Layout';

import Texts from '../../../../texts.json';

import styles from './EmailSent.scss';

const LocalT = new MDText(Texts);

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    google_tag_manager: Function;
  }
}

type EmailSentProps = {
  className?: string;
  email: string;
}

export const EmailSent = ({ className, email }: EmailSentProps) => {
  const isMobile = typeof window !== 'undefined' && /(Android|iPhone|iPad)/.test(window.navigator.userAgent);
  const isIOS = typeof window !== 'undefined' && /(iPhone|iPad)/.test(window.navigator.userAgent);

  const trackClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, provider: string | null) => {
    /**
     * Security model won't let window.open (new tab) fire in a callback
     * So fire and forget, but on iOS we can redirect after callback
     */

    const href = e.currentTarget.getAttribute('href') ?? '';
    const iOSLink = href?.search('http') !== 0;

    SIGN_IN_PAGE_EVENTS.EMAIL_CTA_CLICKED(href);

    if (window.google_tag_manager) {
      if (iOSLink) {
        e.preventDefault();
      }

      GAEvent({
        email_provider_clicked: provider,
        event: 'email_provider_clicked',
        eventCallback() {
          if (iOSLink) {
            window.location.href = href;
          }
        },
        eventTimeout: 300,
      });
    }
  };

  const checkEmail = () => {
    if (email && email.split('@')[1]) {
      const domain = email.split('@')[1].toLowerCase();

      let emailProvider;
      const emailOptions = [];

      switch (true) {
        case /(hotmail|outlook|live.co|msn.co|windowslive.co)/.test(domain):
          emailProvider = [
            'Outlook',
            'https://outlook.live.com/mail/#/inbox',
            'ms-outlook://',
          ];
          break;
        case /(gmail|googlemail|google|motorway.co.uk)/.test(domain):
          emailProvider = [
            'Gmail',
            'https://mail.google.com/mail/u/0/#inbox',
            'googlegmail://',
          ];
          break;
        case /(yahoo|ymail)/.test(domain):
          emailProvider = ['Yahoo', 'https://mail.yahoo.com/', 'ymail://'];
          break;
        case /btinternet/.test(domain):
          emailProvider = ['BT Internet', 'https://www.bt.com/email', null];
          break;
        case /aol.co/.test(domain):
          emailProvider = ['AOL', 'https://mail.aol.co.uk/', null];
          break;
        case /sky.com/.test(domain):
          emailProvider = ['Sky', 'https://skyid.sky.com/signin/email', null];
          break;
        // no default
      }

      if (emailProvider) {
        const [providerName, providerUrl, providerShrotUrl] = emailProvider;
        const emailHref = isIOS ? providerShrotUrl : providerUrl;

        if (emailHref) {
          emailOptions.push(
            <a
              key={providerName}
              className={cx(
                styles.emailIcon,
                styles[`emailIcon${providerName?.replace(' ', '')}`],
              )}
              href={emailHref}
              onClick={(e) => trackClick(e, providerName)}
              rel="noreferrer"
              target="_blank"
            >
              {providerName}
            </a>,
          );
        }
      }

      if (isMobile && isIOS) {
        emailOptions.push(
          <a
            key="ios"
            className={cx(styles.emailIcon, styles.emailIconMail)}
            href="message:"
            onClick={(e) => trackClick(e, 'Apple Mail')}
            rel="noreferrer"
            target="_blank"
          >
            Mail
          </a>,
        );
      }

      if (emailOptions.length > 0) {
        return (
          <div
            className={cx(
              styles.emailQuickLink,
              isIOS ? styles.emailIconSystemIOS : styles.emailIconSystemWeb,
            )}
          >
            <p>
              {LocalT.translate(
                isIOS ? 'signInEmailSent.openIn' : 'signInEmailSent.goTo',
              )}
            </p>
            {emailOptions}
          </div>
        );
      }

      GAEvent({
        email_provider_clicked: `Not known - ${domain}`,
        event: 'email_provider_clicked',
      });
    }

    return null;
  };

  useEffect(() => SIGN_IN_PAGE_EVENTS.EMAIL_SENT_STEP_LOADED(), []);

  return (
    <div className={cx(styles.component, className)}>
      {/* @ts-expect-error empty className prop */}
      <ComponentContent maxWidth={CONTENT_SIZES.big}>
        <h2 className={styles.title}>{LocalT.translate('signInEmailSent.title')}</h2>
        <p className={styles.paragraph}>
          {LocalT.translate('signInEmailSent.detail', { email })}
        </p>
        {checkEmail()}
      </ComponentContent>
    </div>
  );
};
