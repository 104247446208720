import { useCallback } from 'react';

import { authSignIn } from 'PrivateApis';

import { timeoutFetchSentryGuard } from 'Services/fetch';

type useSignInActionProps = {
  getRedirectTo: () => string;
  onSubmitError: (email: string) => void;
  onSubmitSuccess: (email: string) => void;
  setApiError: (err: Record<string, string>) => void;
};

export const useSignInAction = ({
  getRedirectTo,
  onSubmitError,
  onSubmitSuccess,
  setApiError,
}: useSignInActionProps) => {
  const apiErrorMessage = 'No such seller.';
  const apiSuccessMessage = 'Sent login email';

  const signInAction = useCallback(async (email: string) => {
    const { error, message } = await authSignIn({ email, redirectTo: getRedirectTo() })
      .catch((err) => ({ error: err }));

    if (message && message === apiSuccessMessage) {
      return onSubmitSuccess(email);
    }

    if (error) {
      if (error.message === apiErrorMessage) {
        onSubmitError(email);
        setApiError({ message: error.message });
      } else {
        timeoutFetchSentryGuard(error, () => {
          window?.Sentry?.captureException?.(new Error(error), {
            level: 'warning',
            tags: {
              fetch: 'Authn Sign in',
            },
          });
        });
        setApiError(error);
      }
    }
    return undefined;
  }, [getRedirectTo, onSubmitError, onSubmitSuccess, setApiError]);

  return {
    signInAction,
  };
};
