import { useEffect, useState } from 'react';
import cx from 'classnames';

import { useWindowSize } from 'Utilities/hooks';

import { SocialValidation } from '../socialValidation/SocialValidation';
import { HEIGHT_TO_HIDE_FROM_MOBILE, MINIMUM_WINDOW_WIDTH } from '../socialValidation/SocialValidationConfig';

export const SignInSocial = ({ brandName, numberOfCarsSold, showSocialValidation, styles }) => {
  const isMobile = useWindowSize(() => globalThis.innerWidth <= MINIMUM_WINDOW_WIDTH);
  const [isNudgeVisible, setIsNudgeVisible] = useState(false);

  useEffect(() => {
    const listenToScroll = () => {
      setIsNudgeVisible(isMobile && (window.scrollY < HEIGHT_TO_HIDE_FROM_MOBILE));
    };

    setIsNudgeVisible(isMobile && (window.scrollY) < HEIGHT_TO_HIDE_FROM_MOBILE);

    if (isMobile) {
      window.addEventListener('scroll', listenToScroll, { passive: true });
    }

    return () => window.removeEventListener('scroll', listenToScroll, { passive: true });
  }, [isMobile]);

  if (!showSocialValidation) {
    return (null);
  }

  return (
    <div className={cx(styles.socialValidation, { [styles.isVisible]: isNudgeVisible })}>
      <SocialValidation {...{ brandName, numberOfCarsSold }} />
    </div>
  );
};
