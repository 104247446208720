/* eslint-disable */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { useWindowSize } from 'Utilities/hooks';

import { contentConfig, HEIGHT_TO_HIDE_FROM, MINIMUM_WINDOW_WIDTH } from './SocialValidationConfig';

import styles from './SocialValidation.scss';

export type SocialValidationProps = {
  readonly brandName: string;
  readonly numberOfCarsSold: number;
};

export const SocialValidation = ({ brandName, numberOfCarsSold }: SocialValidationProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isOpaque, setIsOpaque] = useState<boolean>(false);

  const isDesktop: unknown = useWindowSize(() => window.innerWidth >= MINIMUM_WINDOW_WIDTH);

  const contentArray = contentConfig({
    brandName,
    numberOfCarsSold,
  });

  useEffect(() => {
    const listenToScroll = () => {
      setIsVisible(isDesktop as boolean && (window.scrollY < HEIGHT_TO_HIDE_FROM));
    };

    setIsVisible(isDesktop as boolean && (window.scrollY) < HEIGHT_TO_HIDE_FROM);

    if (isDesktop) {
      window.addEventListener('scroll', listenToScroll, { passive: true });
    }

    return () => window.removeEventListener('scroll', listenToScroll, { passive: true } as EventListenerOptions);
  }, [isDesktop]);

  useEffect(() => {
    if (contentArray.length <= 1) {
      return;
    }
    const repeatDelay = setInterval(() => {
      setActiveIndex((c) => (c + 1) % contentArray.length);
    }, 6000);
    if (isDesktop && !isVisible) {
      clearInterval(repeatDelay);
    }
    // eslint-disable-next-line consistent-return
    return () => clearInterval(repeatDelay);
  }, [isDesktop, isVisible]);

  return (
    <div
      className={cx(styles.component, {
        [styles.hiddenOnDesktop]: isDesktop && !isVisible,
        [styles.visible]: isVisible,
        [styles.opaque]: isVisible || isOpaque,
      })}
      data-testid="socialValidation"
      onTransitionEnd={(e) => {
        if (e.nativeEvent.propertyName === 'opacity') {
          setIsOpaque(!!parseInt(window.getComputedStyle(e.currentTarget).getPropertyValue('opacity')));
        }
      }}
    >
      {
        contentArray.map(({ icon, text }, index) => (
          <div key={`item-${index + 1}`} className={cx(styles.item, { [styles.showing]: index === activeIndex })}>
            {icon}
            <p>{text}</p>
          </div>
        ))
      }
    </div>
  );
};
